$('.nice-video--control').magnificPopup({
  disableOn: 700,
  type: 'iframe',
  mainClass: 'mfp-fade',
  removalDelay: 160,
  preloader: false,
  fixedContentPos: false,
  callbacks: {
    markupParse: function(template, values, item) {
      $(template).find('iframe').attr('title',item.el.attr('title'));
    }
  }  
});